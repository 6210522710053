import Contact1Button from "./Contact1Button";
import Contact2Item from "./Contact2Item";
import { useEnvironment } from "~/context/EnvironmentContext";

const Contact2 = () => {

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <section className="px-4 py-14 bg-gray-light md:px-7 md:py-[120px]">
      <div className="max-w-[1040px] mx-auto">
        <div className="sp:px-2">
          <h3 className="-indent-2 mb-4 font-black text-base leading-normal md:-indent-[13px] md:mb-6 md:text-[26px] md:leading-[1.3]">
            「飲食転職のプロと一緒に求人を探したい」という方は
            <span className="md:block md:-indent-[26px]">
              <strong className="text-primary text-2xl leading-[1.2] md:text-5xl md:leading-[1.3]">
                『エフジョブ』
              </strong>
              をご利用ください！
            </span>
          </h3>
          <div className="mb-4 text-[15px] leading-[1.8] font-medium md:mb-6 md:text-lg md:leading-[1.8]">
            <p className="-indent-[6px] md:-indent-2">
              『エフジョブ』所属のキャリアアドバイザーがあなたに合った求人情報をご紹介いたします。
            </p>
            <p className="-indent-[6px] md:-indent-2">
              『エフジョブ求人』に掲載されている1万件以上の飲食業界の求人情報の他、サイトには掲載されていない非公開の求人情報もご用意しております。
            </p>
            <p>
              企業へのエントリーから内定まで、あなたの転職活動を全力でサポートいたします。
            </p>
          </div>
          <div className="mb-5 text-center md:mb-6">
            <span className="inline-block w-[104px] md:w-[144px]">
              <img
                src={`${staticImageBucketEndpoint}/public/top/contact2-triangle.webp`}
                width="144"
                height="32"
                alt=""
              />
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-10">
          <Contact2Item
            imgUrl={`${staticImageBucketEndpoint}/public/top/contact1-img-fjob-1.webp`}
            titleSmall="ご相談を検討される方は、"
            titleStrong="『エフジョブ』のお問い合わせページへ！"
            titleStrongIndent={true}
            color="primary"
          >
            <p>
              フォームに情報をご入力・送信いただいた後、キャリアアドバイザーから折り返しご連絡いたします。
              <br />
              『エフジョブ』も『エフジョブ求人』同様に<em>完全無料</em>
              でご利用いただけます。
            </p>
            <div className="mt-3 text-center md:mt-6">
              <Contact1Button
                text="エフジョブのサイトへ"
                icon="exlink"
                linkUrl="https://www.fs-job.net/"
                color="primary"
                isTargetBlank={true}
              ></Contact1Button>
            </div>
          </Contact2Item>
          <Contact2Item
            imgUrl={`${staticImageBucketEndpoint}/public/top/contact1-img-line.webp`}
            titleSmall="「気になる求人情報があれば転職を考えたい」という方は、"
            titleSmallIndent={true}
            titleStrong="LINE公式アカウントでお友達登録！"
            color="green"
          >
            <p>
              LINE公式アカウントをお友達に追加していただくと、あなたにピッタリの求人情報が定期的に届きます。
              <br />
              気になる求人情報が見つかったタイミングで転職活動をスタートしましょう！
            </p>
            <div className="mt-3 text-center md:mt-6">
              <Contact1Button
                text="LINEでお友達登録"
                icon="exlink"
                linkUrl="https://liff.line.me/2001887498-qlJngzxn/landing?follow=%40qyf0197v&lp=M3Sz1b&liff_id=2001887498-qlJngzxn"
                color="green"
                isTargetBlank={true}
              ></Contact1Button>
            </div>
          </Contact2Item>
        </div>
      </div>
    </section>
  );
};

export default Contact2;
