import { useEnvironment } from "~/context/EnvironmentContext";

const Footer = () => {

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <>
      <footer className="pt-20 px-6 pb-6 bg-white md:px-[clamp(28px,5vw,80px)] md:pb-10">
        <div>
          <div className="md:flex md:items-end md:justify-between">
            <a href="/" className="block w-[280px] mb-16 sp:mx-auto md:mb-0">
              <img
                src={`${staticImageBucketEndpoint}/public/logo-orange.webp`}
                width="360"
                height="65"
                alt="エフジョブ求人"
              />
            </a>
            <div className="flex gap-10 justify-center md:gap-6 [&_a]:flex-shrink-0 [&_a]:block [&_a]:h-10 md:[&_a]:h-8 [&_a:hover]:opacity-70 [&_a_img]:w-auto [&_a_img]:h-full">
              <a
                href="https://www.instagram.com/fjob_agent/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${staticImageBucketEndpoint}/public/logo-instagram.webp`}
                  width="32"
                  height="32"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://liff.line.me/2001887498-qlJngzxn/landing?follow=%40qyf0197v&lp=M3Sz1b&liff_id=2001887498-qlJngzxn"
                target="_blank"
                rel="noreferrer"
              >
                <img src={`${staticImageBucketEndpoint}/public/logo-line.webp`} width="32" height="32" alt="LINE" />
              </a>
              <a
                href="https://www.tiktok.com/@fjob_agent?_t=8n6ItL5AuT1&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${staticImageBucketEndpoint}/public/logo-tiktok.webp`}
                  width="69"
                  height="32"
                  alt="TikTok"
                />
              </a>
            </div>
          </div>
          <ul className="mt-10 text-[15px] font-bold leading-normal sp:grid sp:grid-cols-1 sp:gap-8 md:mt-7 md:font-medium md:text-base md:leading-normal md:[&_li]:inline-block md:[&_li]:mr-10 md:[&_li:last-of-type]:mr-0 [&_a:hover]:text-primary [&_a:hover_svg]:fill-primary">
            <li>
              <a href="/#sect-flow">エフジョブ求人の使い方</a>
            </li>
            <li>
              <a
                href="https://www.fs-job.net/"
                target="_blank"
                rel="noreferrer"
              >
                エフジョブ（別サイト）
                <svg className="inline-block w-4 h-4 -mt-1.5 ml-1 fill-default">
                  {/* インラインSVGはネットワークからの取得を行うとセキュリティに引っかかって表示されないので暫定的にローカルを見る */}
                  <use xlinkHref="/symbol-defs.svg#icon-exlink2"></use>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.fs-job.net/contact_corporate/"
                target="_blank"
                rel="noreferrer"
              >
                求人掲載・採用をお考えの方はこちら
                <svg className="inline-block w-4 h-4 -mt-1.5 ml-1 fill-default">
                  {/* インラインSVGはネットワークからの取得を行うとセキュリティに引っかかって表示されないので暫定的にローカルを見る */}
                  <use xlinkHref="/symbol-defs.svg#icon-exlink2"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-10 pt-8 border-t border-primary md:flex md:flex-wrap md:justify-between md:items-end md:pt-10">
          <ul className="text-xs font-medium leading-normal sp:grid sp:grid-cols-1 sp:gap-6 md:flex md:items-end md:gap-10 md:mr-4 md:text-sm md:leading-normal [&_a:hover]:text-primary">
            <li>
              <a
                href="https://www.maproperties.co.jp/about?_gl=1*y6ys71*_ga*MzA5MzgzODMxLjE3MTE2ODIxODI.*_ga_KHB19H4MY5*MTcxODI2NDA4Ni41My4xLjE3MTgyNjQyNDUuNjAuMC4w"
                target="_blank"
                rel="noreferrer"
                className="block w-[211px]"
              >
                <img
                  src={`${staticImageBucketEndpoint}/public/logo-map.webp`}
                  width="211"
                  height="40"
                  alt="株式会社M&A Properties"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.maproperties.co.jp/about?_gl=1*y6ys71*_ga*MzA5MzgzODMxLjE3MTE2ODIxODI.*_ga_KHB19H4MY5*MTcxODI2NDA4Ni41My4xLjE3MTgyNjQyNDUuNjAuMC4w"
                target="_blank"
                rel="noreferrer"
              >
                運営会社
              </a>
            </li>
            <li>
              <a
                href="https://www.fs-job.net/terms/"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
            </li>
            <li>
              <a
                href="https://www.maproperties.co.jp/privacy-policy?_gl=1*pjpx8n*_ga*MzA5MzgzODMxLjE3MTE2ODIxODI.*_ga_KHB19H4MY5*MTcxODI1MTM0MS41Mi4xLjE3MTgyNTEzNDQuNTcuMC4w"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
            </li>
          </ul>
          <small className="block text-xs leading-normal text-[#707070] font-medium sp:mt-8 md:mt-4">
            © ️2024 エフジョブ求人 All rights reserved.
          </small>
        </div>
      </footer>
    </>
  );
};

export default Footer;
