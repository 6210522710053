import React, { createContext, useContext } from 'react';

interface EnvironmentContextProps {
  staticImageBucketEndpoint: string;
}

const EnvironmentContext = createContext<EnvironmentContextProps | undefined>(undefined);

export const EnvironmentProvider: React.FC<{ staticImageBucketEndpoint: string }> = ({ staticImageBucketEndpoint, children }) => {
  return (
    <EnvironmentContext.Provider value={{ staticImageBucketEndpoint }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error('useEnvironment must be used within an EnvironmentProvider');
  }
  return context;
};